import * as React from 'react';

import { Box, Container, Link, Stack, Typography, Divider, Grid } from '@mui/material';

import { Hero } from '../components/Hero';
import { Title, GreenTitle, BodyText } from '../components/helpers';
import { Cards } from '../components/Cards';
import LifetimeStats from '../components/LifetimeStats';
import ByMode from '../components/ByMode';
import { advantages } from '../tabs/advantagesCards';

import background from '../imgs/716.jpg';
import background_image from '../imgs/transport_background.png';

export default function Homepage() {
    return(
        <div>
        <Hero id='home' bgImage={background} />
        {/* <Box sx={{ width: '100%', backgroundColor: 'primary.main' }}>
            <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
                    <BodyText align='center' fontSize={22}>
                        Coming from a family line of logistics professionals, Lakeside meshes together all the best practices from our years of experience to provide you with unrivaled services to meet your needs 24/7. We're powered by Freight-Tec, who is ranked a top 100 broker by "Inc." magazine that has been in business since 1985. We are 1 of only 28 brokers that carries a $100K Guaranteed Payment Bond.
                    </BodyText>
                
                    <Divider sx={{ backgroundColor: 'secondary.light', width: '100%', height: '3px', borderRadius: 5, mb: 5 }} orientation='vertical' flexItem />
                    <Title size='md' type='h2'>Why Lakeside?</Title>
                    <GreenTitle fontStyle='italic'>The Lakeside Advantage</GreenTitle>
                    {advantages.map((arrays, index) => {
                        const advantages_array = Object.keys(arrays).map((k) => arrays[k]);
                        const advantages_flat = advantages_array.flat();
                        const temp_data = advantages_flat.map((e) => (
                            <Cards img={e.img} cardTitle={e.title} imgalt={e.imgalt}>
                                {e.desc}
                            </Cards>
                        ));
                        return(
                            <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} key={index} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 }, width: '100%' }}>
                                <Grid container variant='contained' alignItems={'stretch'} spacing={2}>
                                    {temp_data}
                                </Grid>
                            </Stack>
                        )}
                    )}
            </Container>
        </Box>
        <Box sx={{ backgroundImage: `url(${background_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <Container sx={{ backgroundColor: 'white', borderRadius: 5, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }}} >
                <ByMode />
                <Divider sx={{ backgroundColor: 'primary.main', width: '100%', height: '3px', borderRadius: 5, mb: 5 }} orientation='vertical' flexItem />
                <LifetimeStats />
        </Container>
        </Box> */}
  </div>
    );
}