export const heros = [
    {
        id: '0',
        name: 'home',
        title: 'Providing unparalled value to logistics',
        header: 'We bring together the traditional and digital worlds.',
        header2: 'Stay tuned for the roll out.'
    },
    {
        id: '1',
        name: 'about',
        title: 'Logistics runs in our blood.',
        header: 'Learn about what drives us.'
    },
    {
        id: '2',
        name: 'shippers',
        title: 'Secure. Efficient. Reliable.',
        header: 'Logistics is our life.'
    },
    {
        id: '3',
        name: 'contact',
        title: 'Drop us a line.',
        header: 'We are available 24/7, including Holidays.'
    },
]