// Material imports
import { Box, Typography, Container, Stack, Divider } from '@mui/material';
import { Outlet, Link, NavLink } from 'react-router-dom';

// Image imports
import logolight from '../imgs/1.png';

// Styling constants
const logoStyle = {
    width: 'auto',
    height: '50px',
    cursor: 'pointer',
    alignSelf: 'center'
};

const bold_text = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    textAlign: 'center',
    fontWeight: 'bold',
    alignSelf: 'center',
    fontSize: 'clamp(1rem, 10vw, 0.25rem)',
    color: 'white',
}

function Footer() {
  return (
        <Box sx={{ backgroundColor: 'primary.light' }}>
        <Container sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 5, sm: 6 }, pb: { xs: 5, sm: 6 }}} >
            <Stack spacing={6} direction={{ xs: 'column', sm: 'row' }} useFlexGap sx={{ pt: { xs: 2.5, sm: 5 } }}>
                    <img src={logolight} style={logoStyle} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="body1" sx={bold_text}>Phone: 773-916-9330</Typography>
                        <Typography variant="body1" sx={bold_text}>Email: eric@lksidegroup.com</Typography>
                        <Typography variant="body1" sx={bold_text}>Location: Elmhurst, IL</Typography>
                    </Box>
                    <Divider sx={{ backgroundColor: 'secondary.light', height: { xs: '2px', sm: '16vh' }, width: { xs: '100%', sm: '2px' } }} orientation='horizontal' flexItem />
                    <Typography
                        variant="body1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                            color: 'white'
                        }}
                    >
                            © Lakeside Group {new Date().getFullYear()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Link to='/' style={{ textDecoration: 'none', alignSelf: 'center' }}><Typography variant="body1" sx={bold_text}>Home</Typography></Link>
                        <Link to='/about' style={{ textDecoration: 'none', alignSelf: 'center' }}><Typography variant="body1" sx={bold_text}>About</Typography></Link>
                        <Link to='/contact' style={{ textDecoration: 'none', alignSelf: 'center' }}><Typography variant="body1" sx={bold_text}>Contact</Typography></Link>
                        <Typography variant="body1" sx={bold_text}>G14 Classified</Typography>
                    </Box>
                </Stack>
                </Container>
        </Box>
  );
}

export default Footer;
