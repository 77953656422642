import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import GoogleIcon from './GoogleIcon';

export default function LoginPage() {
  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
        id='login_page'
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 400,
            textAlign: 'center',
            boxShadow: 3,
            padding: 4,
            borderRadius: 2,
            borderColor: 'secondary.dark',
            bgcolor: 'white',
          }}
        >
          <Typography variant="h4" color="primary.dark" gutterBottom>
            Welcome Back
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Let's get started! Please enter your details.
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.target);
              console.log(event.target);
              const data = {
                email: formData.get('email'),
                password: formData.get('password'),
                persistent: formData.get('persistent') === 'on',
              };
              alert(JSON.stringify(data, null, 2));
            }}
          >
            <FormControl fullWidth margin="normal">
              <TextField label='E-Mail' variant='filled' type='email' name='email' fullWidth margin='normal' />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label='Password' variant='filled' type='password' name='password' fullWidth margin='normal' />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginY: 2,
              }}
            >
              <Link href="#" underline="hover">
                <Typography>Forgot password?</Typography>
              </Link>
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Sign In
            </Button>
          </form>
        </Box>
        <Typography variant="body2" color="secondary.light" sx={{ marginTop: 2 }}>
          © Lakeside Group {new Date().getFullYear()}
        </Typography>
      </Box>
  );
}